<template>
  <!-- center -->
  <div
    v-if="toastStore.message"
    class="w-full absolute top-0 text-center z-100 py-4 h-8"
  >
    <span
      class="text-lg py-2 px-4 bg-green-200 rounded shadow-lg"
      :class="{ 'bg-red-200': toastStore.type == 'error' }"
    >
      {{ toastStore.message }}</span
    >
  </div>

  <!-- <div @click="clickPage" v-if="show" class="w-full h-screen  bg-transaprant absolute top-0 text-center z-50 py-4">
    
  </div> -->

  <div
    v-if="toastStore.orderMessage"
    class="border-l-4 border-green-400 bg-green-50 p-4 ml-8 absolute bottom-10 left-10 my-2 h-16 rounded shadow-lg"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationTriangleIcon
          class="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <p class="text-md text-black">
          {{ toastStore.orderMessage }}
          {{ " " }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
const toastStore = useToastStore();

onMounted(() => {});
</script>
