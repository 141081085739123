<template>
  <div
    v-if="active"
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <!--
      Background backdrop, show/hide based on modal state.
  
      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    ></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div
        class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <!--
          Modal panel, show/hide based on modal state.
  
          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div
          class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
        >
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <!-- Heroicon name: outline/exclamation-triangle -->
              <svg
                class="h-6 w-6 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div
              class="mt-3 text-center w-full mr-2 sm:mt-0 sm:ml-4 sm:text-left"
            >
              <h3
                class="text-lg font-medium leading-6 text-gray-900"
                id="modal-title"
              >
                Enter Employee PIN
              </h3>
              <div class="mt-2">
                <p class="text-sm text-red-500" v-if="message">
                  {{ message }} ({{ count }})
                </p>
                <!-- <p class="text-sm text-red-500">
                  {{ originalValue }} | {{ maskedValue }}
                </p> -->
                <input
                  ref="input"
                  :value="maskedValue"
                  @input="onInput"
                  @keydown.enter="onEnter"
                  type="text"
                  class="mt-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              @click="confirm"
              type="button"
              class="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="false" class="w-full absolute top-0 text-center z-50">
    <span
      class="text-lg py-2 px-4 bg-gray-300 rounded shadow"
      @click="employeePinStore.lockScreen"
    >
      Employee : {{ employeePinStore.employee?.name }}</span
    >
  </div>
</template>

<script setup>
const employeePinStore = useEmployeePinStore();
const empStore = useEmployeesStore();
const active = computed(() => employeePinStore.active);
const input = ref(null);
const type = ref("text");
const title = ref("");
const message = ref("");
const count = ref(0);
const maskedValue = ref("");
const originalValue = ref("");
const promiseResolve = ref(null);

const onInput = (event) => {
  const newValue = event.target.value;
  const newValueLength = newValue.length;

  if (newValueLength > originalValue.value.length) {
    originalValue.value += newValue.slice(-1);
  } else {
    originalValue.value = originalValue.value.slice(0, newValueLength);
  }
  maskedValue.value = "X".repeat(newValueLength);
};
const onEnter = () => {
  confirm();
};

watchEffect(() => {
  if (active.value) {
    maskedValue.value = "";
    originalValue.value = "";
    message.value = "";
    setTimeout(() => {
      input.value && input.value.focus();
    }, 20);
  } else {
    // not mounted yet, or the element was unmounted (e.g. by v-if)
  }
});

watchEffect(async () => {
  employeePinStore.employeeList = empStore.employees;
});

const close = () => {};

const confirm = async () => {
  const empList = employeePinStore.employeeList;
  if (empList.length == 0 || !empList) {
    await empStore.fetch();
    employeePinStore.employeeList = empStore.employees;
  }

  let res = employeePinStore.findEmployeeByPin(originalValue.value);
  if (res) {
    count.value == 0;
  } else {
    message.value = "Invalid Pin";
    count.value++;
  }
};
</script>
