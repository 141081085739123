<template>
  <div class="flex h-screen flex-col">
    <!-- Top nav-->
    <div
      v-if="onlineStatusStore.isOnline == false"
      class="relative flex h-8 bg-red-500 text-white text-center"
    >
      <p class="w-full text-lg">
        You are offline since {{ onlineStatusStore.offlineSince }}. (Please
        check internet connection)
      </p>
    </div>
    <header class="relative flex h-16 flex-shrink-0 items-center bg-white">
      <!-- Logo area -->
      <div class="absolute inset-y-0 left-0 lg:static lg:flex-shrink-0">
        <a
          href="#"
          class="flex h-16 w-16 items-center justify-center bg-cyan-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 lg:w-20"
        >
        <svg class="pl-4" aria-hidden="true" viewBox="0 0 52 25" fill="none">
          <path d="M18.724 1.714c-4.538 0-7.376 2.286-8.51 6.857 1.702-2.285 3.687-3.143 5.957-2.57 1.296.325 2.22 1.271 3.245 2.318 1.668 1.706 3.6 3.681 7.819 3.681 4.539 0 7.376-2.286 8.51-6.857-1.701 2.286-3.687 3.143-5.957 2.571-1.294-.325-2.22-1.272-3.245-2.32-1.668-1.705-3.6-3.68-7.819-3.68zM10.214 12c-4.539 0-7.376 2.286-8.51 6.857 1.701-2.286 3.687-3.143 5.957-2.571 1.294.325 2.22 1.272 3.245 2.32 1.668 1.705 3.6 3.68 7.818 3.68 4.54 0 7.377-2.286 8.511-6.857-1.702 2.286-3.688 3.143-5.957 2.571-1.295-.326-2.22-1.272-3.245-2.32-1.669-1.705-3.6-3.68-7.82-3.68z" class="fill-white"></path>
          
          </svg>
        </a>
      </div>

      <!-- Desktop nav area -->
      <div
        class="hidden lg:flex lg:min-w-0 lg:flex-1 lg:items-center lg:justify-between"
      >
        <div class="min-w-0 ">
          <div class="relative max-w-2xl pl-4 focus-within:text-gray-500">
            <!-- <p class="text-lg">{{ shopName }}</p> -->
            <p class="text-md text-black font-bold">{{ shopName }}</p>
            <p
              v-if="jobsStore?.openShop"
              class="text-sm text-gray-600 space-x-2"
              :class="{'bg-red-500 text-white':jobsStore.isOpenShopCrossDate}"
            >
              <span class="">Start POS : </span>
              <span class=""
                >{{
                  displayDate(jobsStore?.openShop?.timestamp, "DD/MMM HH:mm")
                }}
              </span>
              <span v-if="runningTime" class="text-xs text-gray-500" :class="{' text-white':jobsStore.isOpenShopCrossDate}"
                >(running {{ runningTime }})</span
              >
            </p>
          </div>
        </div>
        <div class="ml-10 flex flex-shrink-0 items-center space-x-10 pr-4">
          <nav aria-label="Global" class="flex space-x-10 items-center">
            <div class="relative space-x-10 text-left flex flex-row w-70">
              <!-- <button
                type="button"
                @click="clearJobs"
                class="
                  flex
                  items-center
                  rounded-md
                  text-sm
                  border
                  p-2
                  font-medium
                  text-gray-900
                  focus:outline-none
                  focus:ring-2
                  focus:ring-blue-600
                  focus:ring-offset-2
                "
                id="menu-0-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span>Clear Jobs</span>
            
              </button> -->

              
            <!-- <div v-if="shopStore?.getExpiredDate" class="text-xs flex flex-col text-gray-500 justify-center">
              <span><b>Expire Date :</b> {{ shopStore?.getExpiredDate }}</span>             
            </div> -->


              <button
                type="button"
                @click="lockScreen"
                class="flex items-center rounded-md text-sm border p-2 font-medium text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 min-w-32"
                id="menu-0-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span v-if="employee" class="px-2"
                  >{{ employee?.name }}
                  <span class="text-gray-500 ml-4 font-light"
                    ><i class="fa-light fa-lock"></i> click to lock</span
                  ></span
                >
                <span v-if="!employee"> LOCK SCREEN </span>
                <!-- Heroicon name: mini/chevron-down -->
              </button>
            </div>

            <div class="text-xs flex flex-col text-gray-500">
              <span>mode : {{ posStore?.runmode }}</span>
              <span>store-id : {{ shopStore?.storeId }}</span>
            </div>
          </nav>
          <div class="flex items-center space-x-8 pr-4">
            <span class="inline-flex">
              <a
                href="#"
                class="-mx-1 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500"
              >
                <span class="sr-only">View notifications</span>
                <!-- Heroicon name: outline/bell -->
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                  />
                </svg>
              </a>
            </span>

            <div
              class="relative inline-block text-left"
              @mouseenter="hoverUserIcon = true"
              @mouseleave="hoverUserIcon = false"
            >
              <button
                type="button"
                class="flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 flex-col items-center"
                id="menu-1-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <i
                  class="h-8 w-8 pt-2 bg-gray-300 rounded-full fa-light fa-user"
                ></i>
                <!-- <img
                  class="h-8 w-8 rounded-full"
                  src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                /> -->
              </button>

              <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
              <div
                @mouseenter="hoverUserIcon = true"
                @mouseleave="hoverUserIcon = false"
                class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                :class="{ hidden: !hoverUserIconDebounce }"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-1-button"
                tabindex="-1"
              >
                <div class="py-1" role="none">
                  <!-- Active: "bg-gray-100", Not Active: "" -->
                  <a
                    @click="changeModePos"
                    href="#"
                    class="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-1-item-0"
                    >Change POS mode</a
                  >
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/xtable-882df.appspot.com/o/PrinterCommandsInstaller%2FPrinterServiceXtable.exe?alt=media&token=92b41d78-8f4f-440d-a77d-7966b455073c"
                    class="block px-4 py-2 text-sm text-gray-700"
                    target="_blank"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-1-item-0"
                    >Download Printer Interface</a
                  >

                  <a
                    href="http://localhost:9090"
                    target="_blank"
                    class="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-1-item-0"
                    >Mapping Printers</a
                  >

                  <a
                    @click="logout"
                    href="#"
                    class="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-1-item-1"
                    >Sign Out</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="flex min-h-0 flex-1 overflow-hidden h-screen">
      <!-- Narrow sidebar-->
      <nav
        aria-label="Sidebar"
        :style="{ _height: HEIGHT_REMAINING + 'px', background: '#eaf1fb' }"
        class="block lg:flex-shrink-0 lg:overflow-y-auto border-r border-gray-200 h-screen flex flex-col"
      >
        <div class="relative flex w-20 flex-col space-y-3 p-3">
          <NuxtLink
            v-for="item in navList"
            :key="item.name"
            :to="item.link"
            @click="activeNav = item.name"
          >
            <a
              href="#"
              class="text-white flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg flex flex-col"
              :class="item.name === activeNav ? 'bg-[#d3e3fd]' : ''"
            >
              <!-- Heroicon name: outline/inbox -->
              <i class="text-lg text-gray-700" :class="item.icon"></i>

              <!-- <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
                />
              </svg> -->
              <span class="text-xs text-gray-700" style="fontsize: 0.6rem">{{
                item.name
              }}</span>
            </a>
          </NuxtLink>
        </div>

        <div
          class="absolute bottom-5 left-5 flex-1 text-xs text-gray-500 text-center"
        >
          v{{ packageData.version }}
        </div>
      </nav>

      <!-- Main area -->
      <main
        class="min-w-0 flex-1 border-t border-gray-200 xl:flex overflow-auto"
        :style="{ height: HEIGHT_REMAINING + 'px' }"
      >
        <slot></slot>
        <!-- <slot v-if="employeePin.isPageAllowed"></slot>
        <div v-else class=" text-left w-full my-8 pl-8 text-3xl space-y-4">
         <p> You're not authorized to view this page. </p>
         <p> คุณไม่ได้รับสิทธิ์ในการเข้าหน้านี้ </p>
        </div> -->
      </main>
    </div>

    <SharedToast />
    <ModalEmployeePin />
    <SharedLoading v-if="loading" />
  </div>
</template>

<script lang="ts" setup>
import packageData from "~/package.json";
const activeNav = ref("Dashboard");
const navList = ref([
  // {
  //   name: "Home",
  //   icon: "home",
  //   link: "/",

  // },
  {
    name: "Dashboard",
    icon: "fa-regular fa-house",
    link: "/dashboard/receipts",
  },
  {
    name: "Tables",
    icon: "fa-regular fa-utensils",
    link: "/pos",
  },
  // {
  //   name: "Cart",
  //   icon: "cube",
  //   link: "/cart",
  // },

  {
    name: "History",
    icon: "fa-regular fa-rectangle-history",
    link: "/logs",
  },
  {
    name: "Reports",
    icon: "fa-solid fa-chart-line",

    link: "/reports",
  },
  {
    name: "Menu",
    icon: "fa-regular fa-burger-soda",
    link: "/menu",
  },

  {
    name: "Inventory",
    icon: "fa-regular fa-truck-ramp-box",
    link: "/inventory/list",
  },

  {
    name: "Settings",
    icon: "fa-regular fa-gear",
    link: "/settings",
  },
  // {
  //   name: "Settings2",
  //   icon: "cog",
  //   link: "/settings2",
  // },
  // {
  //   name: "Settings tem",
  //   icon: "cog",
  //   link: "/settings-template",
  // },

  // {
  //   name: "Print Test",
  //   icon: "fa-regular fa-print",
  //   link: "/print-sample",
  // },
]);

const authStore = useAuthStore();
const shopStore = useShopStore();
const jobsStore = useJobsStore();
const posStore = usePosStore();
const employeePin = useEmployeePinStore();
const productsStore = useProductsStore();
const settingsStore = useSettingsStore();
const onlineStatusStore = useOnlineStatusStore();
const loading = computed(() => {
  return posStore.loading || productsStore.loading;
});

console.log("shopStore", shopStore)
const open = ref(false);
const hoverUserIcon = ref(false);
const runningTime = ref("");
const forceOpenUserIcon = ref(true);

const HEIGHT_REMAINING = useSetElementHight(0);

onMounted(() => {
  computeNewRunningTime();
  setInterval(computeNewRunningTime, 1000 * 60);
});

function computeNewRunningTime() {
  let ts = jobsStore.openShop?.timestamp;

  if (ts) {     
     runningTime.value = useRuningTimeDateFormat(ts)
  }
}
watch(
  () => jobsStore.openShop,
  (val) => {
    if (val) {
      computeNewRunningTime();
    }
  }
);

const employee = computed(() => {
  console.log(employeePin.active, "active");

  return employeePin.employee;
});

//debounce hover mouse leave
const hoverUserIconDebounce = useDebounce(hoverUserIcon, 250);

const shopName = computed(() => {
  return settingsStore.getValueBysettingId("name", "Shop Name");
});

const displayDate = useDisplayDate;

const toggle = () => {
  open.value = !open.value;
};

const lockScreen = () => {
  employeePin.lockScreen();
};

const clearJobs = () => {
  const username = useStorage("storeId", null);
  username.value = null;
  jobsStore.clearJobs();
};

const changeModePos = () => {
  useRunningMode().clear();
  //reload page
  location.reload();
};

const logout = () => {
  authStore.logout();
};
</script>

<style></style>
